let routes = [
  {
    path: "/negozi-e-volantini/negozi/:warehouseId",
    meta: {
      breadCrumb: "Negozi e volantini"
    },
    component: () => import("@/views/store/WarehouseDetail.vue")
  },
  {
    path: "/punti-vendita",
    name: "Leaflet",
    meta: {
      //requiresAuth: true,
      breadCrumb: "Volantino",
      slug: "volantino"
    },
    component: () => import("@/views/leaflet/LeafletHome.vue")
  },
  {
    path: "/leaflet/:leafletId",
    name: "LeafletDetails",
    component: () => import("@/views/leaflet/LeafletDetails.vue"),
    props: true
  },
  {
    path: "/gala-world",
    name: "GalaWorld",
    meta: {
      //requiresAuth: true,
      breadCrumb: "Mondo Gala",
      slug: "gala-world"
    },
    component: () => import("@/views/galaWorld/GalaWorldHome.vue")
  },
  {
    path: "/gala-world/shoppingList",
    name: "ShoppingList",
    meta: {
      requiresAuth: true,
      breadCrumb: "Lista della spesa",
      slug: "shopping-list"
    },
    component: () => import("@/views/galaWorld/ShoppingList.vue")
  },
  {
    path: "/gala-world/giftCard",
    name: "GiftCard",
    meta: {
      requiresAuth: true,
      breadCrumb: "Carta regala",
      slug: "gift-card"
    },
    component: () => import("@/views/galaWorld/GiftCardHome.vue")
  },
  {
    path: "/gala-world/receiptLottery",
    name: "ReceiptLottery",
    meta: {
      requiresAuth: true,
      breadCrumb: "Receipt Lottery",
      slug: "receipt-lottery"
    },
    component: () => import("@/views/galaWorld/ReceiptLottery.vue")
  },
  {
    path: "/gala-world/regusto",
    name: "RegustoWidget",
    meta: {
      requiresAuth: false,
      breadCrumb: "Regusto Widget",
      slug: "regusto-widget"
    },
    component: () => import("@/views/galaWorld/RegustoWidget.vue")
  },
  {
    path: "/gala-world/contact-form",
    name: "ContactForm",
    meta: {
      requiresAuth: false,
      breadCrumb: "Parla con noi",
      slug: "contact-form"
    },
    component: () => import("@/views/galaWorld/ContactForm.vue")
  },
  {
    //gestione gerarchica del catalogo per poter gestire il reset filters all'ingresso.
    path: "/catalog",
    component: {
      render(c) {
        return c("router-view");
      }
    },
    beforeEnter(to, from, next) {
      global.EventBus.$emit("resetFilters");
      next();
    },
    children: [
      {
        path: "/search",
        name: "Search",
        component: () => import("@/views/catalog/Search.vue"),
        props: route => ({
          query: route.params,
          parent_category_id: route.params.parent_category_id,
          filter: route.params.filter,
          mode: "search"
        }),
        meta: { slug: "search" }
      },
      {
        path: "/fast-search",
        name: "FastSearch",
        component: () => import("@/views/catalog/FastSearch.vue"),
        props: route => ({
          query: route.query.q
        }),
        meta: { slug: "fast-search" }
      },
      {
        path: "/product/:slug",
        name: "Product",
        component: () => import("@/views/catalog/Product.vue"),
        props: true
      },
      {
        path: "/*",
        name: "Category",
        component: () => import("@/views/catalog/Category.vue"),
        meta: {
          keepAlive: true
        },
        //commento
        props: route => ({
          slug: route.params.pathMatch,
          categoryId: route.query.categoryId
        })
      }
    ]
  }
];

export default routes;
