var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "fast-search-menu",
      staticStyle: { "background-image": "url('/fast-search-bg.svg')" }
    },
    [
      _c("v-layout", { attrs: { "fill-height": "", column: "" } }, [
        _c("h3", {
          staticClass: "text-h2 font-weight-bold mx-auto my-2 text-center",
          domProps: { innerHTML: _vm._s(_vm.title) }
        }),
        _vm.description
          ? _c("div", {
              staticClass: "text-center",
              domProps: { innerHTML: _vm._s(_vm.description) },
              on: { click: _vm.clicked }
            })
          : _vm._e(),
        _c(
          "div",
          { staticClass: "form-container d-flex justify-center" },
          [
            _c(
              "v-form",
              {
                staticClass: "d-flex flex-column justify-center",
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.addProduct.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "v-text-field",
                  {
                    attrs: {
                      dense: "",
                      label: _vm.$t(
                        "fastSearch.fastSearchMenu.searchPlaceholder"
                      )
                    },
                    on: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.addProduct.apply(null, arguments)
                      },
                      paste: _vm.pasteText
                    },
                    model: {
                      value: _vm.searchString,
                      callback: function($$v) {
                        _vm.searchString = $$v
                      },
                      expression: "searchString"
                    }
                  },
                  [
                    _c(
                      "template",
                      { slot: "append" },
                      [
                        _c("v-icon", { on: { click: _vm.addProduct } }, [
                          _vm._v("$plus")
                        ])
                      ],
                      1
                    )
                  ],
                  2
                ),
                _c(
                  "v-list",
                  { staticClass: "pt-0 product-list" },
                  _vm._l(_vm.productList, function(product) {
                    return _c(
                      "v-list-item",
                      { key: product },
                      [
                        _c("v-list-item-title", [_vm._v(_vm._s(product))]),
                        _c(
                          "v-list-item-action",
                          [
                            _c(
                              "v-icon",
                              {
                                attrs: { color: "default" },
                                on: {
                                  click: function($event) {
                                    return _vm.removeFastSearchWord(product)
                                  }
                                }
                              },
                              [_vm._v("$close")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-column align-center justify-center"
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "my-3",
                        attrs: { depressed: "", color: "primary", large: "" },
                        on: { click: _vm.fastSearch }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("fastSearch.fastSearchMenu.searchBtn")
                            ) +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "my-3",
                        attrs: {
                          depressed: "",
                          color: "primary",
                          large: "",
                          text: ""
                        },
                        on: { click: _vm.removeAllFastSearchWords }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("fastSearch.fastSearchMenu.clearSearches")
                            ) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }