var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex align-center justify-center barcode-container mx-2"
    },
    [
      _c(
        "v-btn",
        {
          staticClass: "primary--text barcode-btn my-3",
          class: { "no-border": _vm.$vuetify.breakpoint.smAndDown },
          attrs: { loading: _vm.loading, large: "", rounded: "", text: "" },
          on: {
            click: function($event) {
              return _vm.scan()
            }
          }
        },
        [
          _vm._v(" Scansiona un QR Code "),
          _c(
            "v-icon",
            { staticClass: "pl-2", attrs: { right: "", large: "" } },
            [_vm._v(" $qrCode ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }