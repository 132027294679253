<template>
  <div class="d-flex align-center justify-center barcode-container mx-2">
    <v-btn
      class="primary--text barcode-btn my-3"
      :class="{ 'no-border': $vuetify.breakpoint.smAndDown }"
      :loading="loading"
      large
      rounded
      text
      @click="scan()"
    >
      Scansiona un QR Code
      <v-icon right large class="pl-2">
        $qrCode
      </v-icon>
    </v-btn>
  </div>
</template>
<style lang="scss" scoped>
.icon-barcode-scan:before {
  font-size: 24px;
}
</style>
<script>
export default {
  name: "Barcode",
  props: ["formats"],
  data() {
    return {
      loading: false
    };
  },
  computed: {},
  methods: {
    scan() {
      if (window.cordova && window.cordova.plugins.barcodeScanner) {
        try {
          this.loading = true;
          // eslint-disable-next-line no-undef
          cordova.plugins.barcodeScanner.scan(
            result => {
              if (result) {
                this.loading = false;
                const barcode = result.text.length === 12 ? '0' + result.text : result.text;
                this.$emit("input", barcode);
              }
            },
            error => {
              this.loading = false;
              console.error("Scanning failed", error);
              if (
                error
                  .toString()
                  .toLowerCase()
                  .includes("illegal access")
              ) {
                this.$dialog.confirm({
                  text: `<p class='text-center font-weight-semibold pre-wrap py-3'>${this.$t(
                    "message.cameraPermission"
                  )}</p>`,
                  class: "",
                  actions: {}
                });
              }
            },
            {
              showFlipCameraButton: true,
              showTorchButton: true,
              resultDisplayDuration: 500, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
              formats:
                this.formats || "EAN_8,EAN_13,CODE_128,CODE_39,CODE_93,CODABAR"
            }
          );
        } catch (err) {
          console.log(err);
          this.loading = false;
        }
      } else {
        console.log("Barcode Scanner plugin not available");
        // var code = prompt("Inserisci il codice", "");
        // if (code) {
        //   this.$emit("input", code);
        // }
      }
    }
  }
};
</script>
<style lang="scss">
.barcode-container {
  .barcode-btn {
    font-weight: 600;
  }
}
</style>
