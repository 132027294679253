var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-fab-transition",
    [
      _c(
        "v-btn",
        {
          directives: [
            {
              name: "scroll",
              rawName: "v-scroll",
              value: _vm.onScroll,
              expression: "onScroll"
            },
            {
              name: "show",
              rawName: "v-show",
              value: _vm.fab,
              expression: "fab"
            }
          ],
          staticClass: "scroll-top",
          attrs: {
            fab: "",
            fixed: "",
            center: "",
            bottom: "",
            rounded: "",
            color: "primary"
          },
          on: { click: _vm.toTop }
        },
        [
          _c("v-icon", [_vm._v("$chevronUp")]),
          _c("span", [_vm._v("Torna all'inizio")])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }