var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    {
      staticClass: "grey lighten-2",
      attrs: { "no-gutters": "", align: "center" }
    },
    [
      _c(
        "v-col",
        { staticClass: "d-flex justify-start mx-2", attrs: { cols: "1" } },
        [
          _c(
            "div",
            { staticClass: "pa-1 white rounded-sm" },
            [
              _c("v-img", {
                attrs: {
                  eager: "",
                  src: _vm.serviceImg,
                  height: "45",
                  width: "45",
                  contain: "",
                  position: "center"
                }
              })
            ],
            1
          )
        ]
      ),
      _c(
        "v-col",
        { staticClass: "d-flex align-center", attrs: { cols: "10" } },
        [
          _c("div", { staticClass: "d-flex flex-column justify-start w-100" }, [
            _c(
              "div",
              {
                staticClass: "info-text",
                on: { click: _vm.openAddressSelector }
              },
              [
                _c("span", { staticClass: "font-weight-bold" }, [
                  _vm._v(_vm._s(_vm.serviceName))
                ]),
                _vm._v(" " + _vm._s(_vm.shippingAddress)),
                _c("v-icon", { attrs: { small: "", right: "" } }, [
                  _vm._v("$edit")
                ])
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "info-text",
                attrs: { disabled: !_vm.editableTimeslot },
                on: { click: _vm.openTimeslotSelector }
              },
              [
                _c("span", { staticClass: "font-weight-bold" }, [
                  _vm._v(_vm._s(_vm.$t("cart.cartInfo.timeslot")))
                ]),
                _vm._v(" " + _vm._s(_vm.timeslot)),
                _c("v-icon", { attrs: { small: "", right: "" } }, [
                  _vm._v("$edit")
                ])
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }