var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.i18nLoaded
    ? _c(
        "v-app",
        {
          class: [
            _vm.getKeyboardOpen ? _vm.classes + " keyboard-open" : _vm.classes,
            { "platform-ios": _vm.platformIos }
          ]
        },
        [
          _c(
            "div",
            [
              _c("Navbar", {
                key: _vm.key,
                attrs: {
                  primaryLinks: _vm.primaryLinks,
                  infoLinks: _vm.infoLinks,
                  applicationConfig: _vm.applicationConfig
                }
              }),
              _c(
                "v-navigation-drawer",
                {
                  staticClass: "categories",
                  attrs: {
                    touchless: "",
                    left: "",
                    app: "",
                    clipped: "",
                    temporary: "",
                    width: "360"
                  },
                  model: {
                    value: _vm.drawerLeft,
                    callback: function($$v) {
                      _vm.drawerLeft = $$v
                    },
                    expression: "drawerLeft"
                  }
                },
                [
                  _c("CategoryTree", {
                    attrs: {
                      infoLinks: _vm.infoLinks,
                      primaryLinks: _vm.primaryLinks
                    },
                    on: {
                      close: function($event) {
                        _vm.drawerLeft = false
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "v-navigation-drawer",
                {
                  staticClass: "cart-drawer white",
                  attrs: {
                    "disable-resize-watcher": "",
                    touchless: "",
                    temporary: "",
                    clipped: "",
                    right: "",
                    app: "",
                    width: "600",
                    id: "cart-right-drawer"
                  },
                  model: {
                    value: _vm.drawerRight,
                    callback: function($$v) {
                      _vm.drawerRight = $$v
                    },
                    expression: "drawerRight"
                  }
                },
                [
                  _vm.drawerRight
                    ? _c(
                        "div",
                        { staticClass: "cart-info" },
                        [
                          _c("CartInfoCompact", {
                            staticClass: "infos",
                            attrs: { isCart: true }
                          }),
                          _c("CartItemList", {
                            staticClass: "items-list",
                            attrs: {
                              full: _vm.$vuetify.breakpoint.xs,
                              checkMobile: _vm.iosMobile,
                              showCartButton: false
                            }
                          }),
                          _c("CartSummary", {
                            staticClass: "summary py-0",
                            class: {
                              "cart-item-list-no-checkout-osx": _vm.iosMobile
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]
              ),
              _c(
                "v-main",
                [
                  _c("ebsn-style", {
                    attrs: {
                      target: _vm.applicationConfig,
                      path: "category_advanced.CSS_STYLE"
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "content-wrap" },
                    [
                      _vm.loading
                        ? _c("v-skeleton-loader", {
                            attrs: {
                              type: "actions, image, article,image",
                              loading: _vm.loading
                            }
                          })
                        : _c("router-view")
                    ],
                    1
                  ),
                  _vm.applicationConfig
                    ? _c("category-block", {
                        staticClass: "category-block category-block-tutorial",
                        attrs: {
                          target: _vm.applicationConfig,
                          position: "tutorial",
                          container: false
                        }
                      })
                    : _vm._e(),
                  _c("Footer", {
                    attrs: {
                      links: _vm.footerLinks,
                      applicationConfig: _vm.applicationConfig
                    }
                  })
                ],
                1
              ),
              !_vm.isCordova ? _c("ScrollTop") : _vm._e(),
              _c("ShowCard"),
              _c("SsoUserCard")
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }