var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isCheckout
        ? _c(
            "div",
            { staticClass: "accept-replacement" },
            [
              _c("v-checkbox", {
                attrs: {
                  "hide-details": "",
                  label: _vm.$t("cartDetail.acceptAlternatives")
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                  }
                },
                model: {
                  value: _vm.acceptAlternatives,
                  callback: function($$v) {
                    _vm.acceptAlternatives = $$v
                  },
                  expression: "acceptAlternatives"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.isCheckout
        ? _c("v-textarea", {
            ref: "note",
            staticClass: "mt-3 px-2 note-textarea",
            attrs: {
              label: _vm.$t("cartDetail.note"),
              "auto-grow": "",
              dense: "",
              solo: "",
              flat: "",
              "hide-details": "auto",
              rows: "1",
              "row-height": "15",
              "prepend-inner-icon": "$edit",
              rules: _vm.notesRule
            },
            on: {
              blur: function($event) {
                return _vm.updateNote("order_comment", _vm.tempUserNotes)
              }
            },
            model: {
              value: _vm.orderComment,
              callback: function($$v) {
                _vm.orderComment = $$v
              },
              expression: "orderComment"
            }
          })
        : _vm._e(),
      _c(
        "v-list",
        {
          staticClass: "cart-item-list",
          class: { "checkout-item-list": _vm.isCheckout },
          attrs: { id: "cartItemList", flat: "", subheader: "" }
        },
        [
          [
            _c(
              "v-subheader",
              {
                staticClass:
                  "text-caption d-flex justify-space-between default--text",
                class: _vm.isCheckout ? "px-0" : "px-2"
              },
              [
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$tc("cart.cartItemCount", _vm.cart.totalItems)
                    )
                  }
                }),
                _c("v-switch", {
                  attrs: { label: "Dividi per categoria" },
                  model: {
                    value: _vm.showSectors,
                    callback: function($$v) {
                      _vm.showSectors = $$v
                    },
                    expression: "showSectors"
                  }
                })
              ],
              1
            )
          ],
          !_vm.showSectors
            ? _c(
                "div",
                {
                  staticClass: "no-sectors",
                  class: [
                    { "cart-item-list-no-checkout": !_vm.isCheckout },
                    { "cart-item-list-no-checkout-higher": !_vm.isCheckout }
                  ]
                },
                _vm._l(_vm.cart.cartItems, function(cartItem) {
                  return _c(
                    "div",
                    {
                      key: cartItem.cartItemId,
                      staticClass: "align-center px-md-2"
                    },
                    [
                      _c("CartItem", {
                        attrs: {
                          item: cartItem,
                          full: _vm.full,
                          isCheckout: _vm.isCheckout,
                          showCartButton: _vm.showCartButton
                        }
                      })
                    ],
                    1
                  )
                }),
                0
              )
            : _c(
                "v-expansion-panels",
                {
                  attrs: { accordion: "", flat: "", multiple: "", tile: "" },
                  model: {
                    value: _vm.panel,
                    callback: function($$v) {
                      _vm.panel = $$v
                    },
                    expression: "panel"
                  }
                },
                _vm._l(_vm.itemGroups, function([categoryId, group]) {
                  return _c(
                    "v-expansion-panel",
                    { key: categoryId },
                    [
                      _c(
                        "v-expansion-panel-header",
                        {
                          staticClass:
                            "text-caption primary--text grey lighten-3 px-3",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return (() => {}).apply(null, arguments)
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "actions",
                                fn: function() {
                                  return [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: { color: "secondary", small: "" }
                                      },
                                      [_vm._v(" $chevronDown ")]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex align-center black--text" },
                            [
                              _c(
                                "span",
                                { staticClass: "font-weight-bold mr-2" },
                                [_vm._v(_vm._s(group.name))]
                              ),
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(`(${group.products.length})`) +
                                    " "
                                )
                              ])
                            ]
                          )
                        ]
                      ),
                      _c(
                        "v-expansion-panel-content",
                        _vm._l(group.products, function(item) {
                          return _c("CartItem", {
                            key: item.itemId,
                            staticClass: "sectorItem",
                            attrs: {
                              item: item,
                              full: _vm.full,
                              isCheckout: _vm.isCheckout
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }