var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-fab-transition",
    [
      _c(
        "v-btn",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.$route.path === "/",
              expression: "$route.path === '/'"
            }
          ],
          staticClass: "show-card-btn",
          attrs: {
            fab: "",
            fixed: "",
            center: "",
            rounded: "",
            color: "secondary",
            loading: _vm.showOverlay
          },
          on: { click: _vm.showDetails }
        },
        [
          _c("img", {
            staticClass: "mr-2",
            attrs: { src: "/img_layout/carta-gala.svg" }
          }),
          _c("span", [_vm._v("Carta Gala Club")])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }