var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.cart.cartId > 0
    ? _c(
        "v-card",
        {
          staticClass: "cart-summary text-right",
          attrs: { tile: "", flat: "" }
        },
        [
          _c(
            "v-card-actions",
            { staticClass: "justify-end" },
            [
              _c(
                "v-row",
                {
                  attrs: {
                    "no-gutters": "",
                    align: "center",
                    justify: "center"
                  }
                },
                [
                  _vm.cart.totalItems > 0
                    ? _c(
                        "v-col",
                        { staticClass: "px-1", attrs: { cols: "6" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "primary",
                              attrs: {
                                to: "/checkout",
                                color: "primary",
                                "x-large": "",
                                block: "",
                                depressed: ""
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("cart.button.goToPayment")))]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    {
                      staticClass: "px-1",
                      attrs: { cols: "6", align: "center" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-space-between" },
                        [
                          _c("h1", [
                            _vm._v(_vm._s(_vm.$t("cartSummary.grossTotal")))
                          ]),
                          _c("h1", { staticClass: "value" }, [
                            _vm._v(
                              _vm._s(_vm.$n(_vm.cart.totalPrice, "currency"))
                            )
                          ])
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _c("v-card", [_vm._v(_vm._s(_vm.$t("no-cart")))])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }