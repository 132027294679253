var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cart-item" },
    [
      _c(
        "v-list-item",
        {
          class: _vm.isCheckout ? "py-3 is-checkout" : "py-2 is-not-checkout",
          attrs: {
            to: {
              name: "Product",
              params: { slug: _vm.item.product.slug }
            }
          }
        },
        [
          _c(
            "v-row",
            {
              staticClass: "w-100 my-2 my-sm-0",
              attrs: { align: "center", justify: "space-between" }
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "pa-0",
                  attrs: { cols: "12", sm: _vm.isCheckout ? 5 : 6, lg: "6" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c(
                        "v-list-item-avatar",
                        {
                          staticClass: "my-0",
                          attrs: {
                            width: _vm.full ? "70" : "70",
                            height: _vm.full ? "70" : "70",
                            rounded: "0"
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: _vm.item.product.mediaURL,
                              alt: _vm.item.product.name,
                              onerror:
                                "this.onerror=null;this.src='/no-icon.png'"
                            }
                          })
                        ]
                      ),
                      _c(
                        "v-list-item-content",
                        { staticClass: "default--text pa-0 pa-sm-2" },
                        [
                          _c(
                            "div",
                            { staticClass: "font-weight-bold text-body-2" },
                            [_vm._v(" " + _vm._s(_vm.item.product.name) + " ")]
                          ),
                          _c(
                            "v-list-item-subtitle",
                            { staticClass: "text-uppercase text-body-2" },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.item.product.shortDescr) + " "
                              )
                            ]
                          ),
                          _vm.item.cartItemInfo.size
                            ? _c(
                                "v-list-item-subtitle",
                                { staticClass: "cart-item-info" },
                                [
                                  _c("i", [
                                    _vm._v(
                                      "Preferenza: " +
                                        _vm._s(_vm.item.cartItemInfo.size)
                                    )
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _vm.itemAdjustment &&
                          _vm.itemAdjustment.userGiftCertificateId &&
                          _vm.itemAdjustment.giftCertificate
                            ? _c(
                                "v-list-item-subtitle",
                                { staticClass: "cart-item-info promo--text" },
                                [
                                  _c("em", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.itemAdjustment.giftCertificate.name
                                      ) + ": "
                                    )
                                  ])
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm.$vuetify.breakpoint.xsOnly &&
                      !(
                        _vm.item.product.productInfos &&
                        _vm.item.product.productInfos.USER_DISABLED == "1"
                      )
                        ? _c(
                            "v-list-item-action",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    icon: "",
                                    "aria-label": "Rimuovi dal carrello",
                                    color: "primary"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.remove.apply(null, arguments)
                                    },
                                    mousedown: function($event) {
                                      $event.stopPropagation()
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "secondary" } },
                                    [_vm._v("$delete")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              ),
              _c(
                "v-col",
                {
                  staticClass: "pa-0",
                  attrs: { cols: "12", sm: _vm.isCheckout ? 7 : 6, lg: "6" }
                },
                [
                  _c(
                    "v-list-item-action",
                    { staticClass: "mr-0 w-100 mt-3 mt-sm-0 mb-0" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "d-flex align-center w-100",
                          class:
                            _vm.isCheckout || _vm.$vuetify.breakpoint.xs
                              ? "justify-space-between"
                              : "justify-end"
                        },
                        [
                          _vm.isCheckout
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-column cart-item-price"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-space-between align-end"
                                    },
                                    [
                                      _c("div", { staticClass: "cur-price" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$n(
                                                _vm.item.product.priceDisplay,
                                                "currency"
                                              )
                                            )
                                        ),
                                        _vm.item.product.productInfos
                                          .TIPOLOGIA != "Pezzo"
                                          ? _c(
                                              "span",
                                              { staticClass: "unit-price" },
                                              [
                                                _vm._v(
                                                  "/" + _vm._s(_vm.unit) + " "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "text-right w-100 iva" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.item.product.ivaCategory.descr
                                        )
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          !_vm.showQntyInput
                            ? _c("ProductQty", {
                                attrs: {
                                  item: _vm.item,
                                  product: _vm.item.product,
                                  showCartButton: _vm.showCartButton,
                                  updateCart: true
                                }
                              })
                            : _c("div", { staticClass: "product-qty" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "qnty-manual-input qty-wrap d-flex align-center rounded-md",
                                    class: { "not-empty": _vm.quantity > 0 },
                                    attrs: { transition: "fab-transition" }
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "close px-2",
                                        attrs: { role: "button" },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                            return _vm.toggleShowInput(false)
                                          },
                                          mousedown: function($event) {
                                            $event.stopPropagation()
                                          }
                                        }
                                      },
                                      [_c("v-icon", [_vm._v("$delete")])],
                                      1
                                    ),
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: {
                                          bottom: "",
                                          "open-on-hover": false,
                                          "open-on-click": true,
                                          "max-width": "500"
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "activator",
                                            fn: function({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        directives: [
                                                          {
                                                            name: "mask",
                                                            rawName: "v-mask",
                                                            value: "###",
                                                            expression: "'###'"
                                                          }
                                                        ],
                                                        ref: "manualInput",
                                                        staticClass:
                                                          "manual-input",
                                                        attrs: {
                                                          "hide-details": "",
                                                          autofocus: "",
                                                          outlined: "",
                                                          dense: "",
                                                          type: "number"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            $event.preventDefault()
                                                          },
                                                          keyup: [
                                                            function($event) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "enter",
                                                                  13,
                                                                  $event.key,
                                                                  "Enter"
                                                                )
                                                              )
                                                                return null
                                                              return _vm.toggleShowInput(
                                                                true,
                                                                null,
                                                                true
                                                              )
                                                            },
                                                            function($event) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "esc",
                                                                  27,
                                                                  $event.key,
                                                                  [
                                                                    "Esc",
                                                                    "Escape"
                                                                  ]
                                                                )
                                                              )
                                                                return null
                                                              return _vm.toggleShowInput(
                                                                false
                                                              )
                                                            }
                                                          ],
                                                          mousedown: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                          }
                                                        },
                                                        model: {
                                                          value: _vm.qntyInput,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.qntyInput = $$v
                                                          },
                                                          expression:
                                                            "qntyInput"
                                                        }
                                                      },
                                                      "v-text-field",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  )
                                                )
                                              ]
                                            }
                                          }
                                        ])
                                      },
                                      [
                                        this.product.productInfos.QTY_LOCKED !=
                                          "true" ||
                                        (this.product.productInfos.QTY_LOCKED ==
                                          "true" &&
                                          !_vm.modulus(_vm.qntyInput))
                                          ? _c("span", {
                                              staticClass: "qntity-tooltip",
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.$t(
                                                    "product.quantity.confirmQntity",
                                                    {
                                                      qntyInput: _vm.qntyInput
                                                    }
                                                  )
                                                )
                                              }
                                            })
                                          : _vm._e(),
                                        this.product.productInfos.QTY_LOCKED ==
                                          "true" &&
                                        _vm.modulus(_vm.qntyInput) &&
                                        parseInt(_vm.qntyInput) <=
                                          parseInt(
                                            this.product.productInfos.QTY_MULT
                                          )
                                          ? _c("span", {
                                              staticClass: "qntity-tooltip",
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.$t(
                                                    "product.quantity.editQntityMin",
                                                    {
                                                      lowerInput: _vm.lowerInput
                                                    }
                                                  )
                                                )
                                              }
                                            })
                                          : _vm._e(),
                                        this.product.productInfos.QTY_LOCKED ==
                                          "true" &&
                                        _vm.modulus(_vm.qntyInput) &&
                                        parseInt(_vm.qntyInput) >
                                          parseInt(
                                            this.product.productInfos.QTY_MULT
                                          )
                                          ? _c("span", {
                                              staticClass: "qntity-tooltip",
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.$t(
                                                    "product.quantity.editQntityMinMax",
                                                    {
                                                      lowerInput:
                                                        _vm.lowerInput,
                                                      higherInput:
                                                        _vm.higherInput
                                                    }
                                                  )
                                                )
                                              }
                                            })
                                          : _vm._e()
                                      ]
                                    ),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "check px-2",
                                        attrs: { role: "button" },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                            return _vm.toggleShowInput(
                                              true,
                                              null,
                                              true
                                            )
                                          },
                                          mousedown: function($event) {
                                            $event.stopPropagation()
                                          }
                                        }
                                      },
                                      [_c("v-icon", [_vm._v("$success")])],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]),
                          _c(
                            "div",
                            { staticClass: "price font-weight-bold ml-2" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$n(_vm.item.grossTotal, "currency")
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _vm.$vuetify.breakpoint.smAndUp &&
                          !(
                            _vm.item.product.productInfos &&
                            _vm.item.product.productInfos.USER_DISABLED == "1"
                          )
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    icon: "",
                                    "aria-label": "Rimuovi dal carrello",
                                    color: "primary"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.remove.apply(null, arguments)
                                    },
                                    mousedown: function($event) {
                                      $event.stopPropagation()
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "secondary" } },
                                    [_vm._v("$delete")]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.isCheckout
        ? _c("v-checkbox", {
            staticClass: "accept-alternatives my-1 pt-0",
            attrs: { dense: "", color: "primary", "hide-details": "" },
            scopedSlots: _vm._u(
              [
                {
                  key: "label",
                  fn: function() {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("cartDetail.acceptAlternatives")))
                      ])
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              2841946715
            ),
            model: {
              value: _vm.acceptAlternative,
              callback: function($$v) {
                _vm.acceptAlternative = $$v
              },
              expression: "acceptAlternative"
            }
          })
        : _vm._e(),
      _vm.isCheckout
        ? _c("v-textarea", {
            ref: "note",
            staticClass: "note-textarea",
            attrs: {
              label: _vm.$t("cartDetail.note"),
              "auto-grow": "",
              dense: "",
              solo: "",
              flat: "",
              "hide-details": "auto",
              rows: "1",
              "row-height": "15",
              "prepend-inner-icon": "$edit",
              rules: _vm.notesRule
            },
            on: {
              blur: function($event) {
                return _vm.updateNote(_vm.item, "user_notes", _vm.tempUserNotes)
              }
            },
            model: {
              value: _vm.userNotes,
              callback: function($$v) {
                _vm.userNotes = $$v
              },
              expression: "userNotes"
            }
          })
        : _vm._e(),
      _c("v-divider"),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400" },
          model: {
            value: _vm.removeDialog,
            callback: function($$v) {
              _vm.removeDialog = $$v
            },
            expression: "removeDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-text", { staticClass: "body-1 pt-8" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("message.remove", {
                        name: this.product.name,
                        quantity: this.quantity,
                        unit: this.unit
                      })
                    ) +
                    " "
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { outlined: "", text: "" },
                      on: {
                        click: function($event) {
                          _vm.removeDialog = false
                        }
                      }
                    },
                    [_vm._v(" No ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary white--text",
                      attrs: { text: "" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.doRemove.apply(null, arguments)
                        },
                        mousedown: function($event) {
                          $event.stopPropagation()
                        }
                      }
                    },
                    [_vm._v(" Si ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }