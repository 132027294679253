var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-bottom-navigation",
    {
      staticClass: "bottom-nav-bar",
      attrs: { grow: "", fixed: "", color: "primary" }
    },
    [
      _c(
        "v-btn",
        { attrs: { to: { name: "Home" }, exact: "" } },
        [
          _c("span", [_vm._v(_vm._s(_vm.$t("bottom.home")))]),
          _c("v-icon", [_vm._v("$home")])
        ],
        1
      ),
      _c(
        "v-btn",
        {
          class: {
            "v-btn--active":
              _vm.$route.path.startsWith("/leaflet") ||
              _vm.$route.path.startsWith("/punti-vendita")
          },
          on: {
            click: function($event) {
              return _vm.handleLeafletRoute()
            }
          }
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.$t("bottom.leaflet")))]),
          _c("v-icon", { staticClass: "font-weight-semibold" }, [
            _vm._v("$volantino")
          ])
        ],
        1
      ),
      _c(
        "v-btn",
        {
          class: { "v-btn--active": _vm.$route.path.startsWith("/coupon") },
          on: {
            click: function($event) {
              return _vm.handleLogin("/coupon")
            }
          }
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.$t("bottom.coupon")))]),
          _c("v-icon", [_vm._v("$coupon")])
        ],
        1
      ),
      _c("v-btn", { attrs: { to: { name: "GalaWorld" } } }, [
        _c("span", [_vm._v(_vm._s(_vm.$t("bottom.galaWorld")))]),
        _c("img", { attrs: { src: "/logo/logo_mobile.png" } })
      ]),
      _c(
        "v-btn",
        { attrs: { to: { name: "Dashboard" } } },
        [
          _c("span", [_vm._v(_vm._s(_vm.$t("bottom.account")))]),
          _c("v-icon", [_vm._v("$profile")])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }