var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "h-100 pb-0 pt-12 px-0 autocomplete", attrs: { fluid: "" } },
    [
      _c(
        "div",
        { staticClass: "d-flex flex-column pa-4 autocomplete-wraper" },
        [
          _c(
            "label",
            { staticClass: "text-uppercase font-weight-bold text-center py-3" },
            [_vm._v(_vm._s(_vm.$t("navbar.searchPlaceholder")))]
          ),
          _c(
            "v-combobox",
            {
              ref: "autocomplete",
              staticClass: "search-input",
              attrs: {
                clearable: "",
                filled: "",
                solo: "",
                dense: "",
                rounded: "",
                items: _vm.getItems,
                "search-input": _vm.search,
                "no-filter": "",
                label: _vm.isListening ? _vm.$t("navbar.speechActive") : "",
                placeholder: _vm.isListening
                  ? _vm.$t("navbar.speechActive")
                  : "",
                flat: "",
                "item-text": "name",
                "item-value": "name",
                "hide-details": "",
                "hide-no-data": "",
                "return-object": "",
                type: "search",
                "menu-props": {
                  closeOnContentClick: true,
                  contentClass: "searches-menu"
                }
              },
              on: {
                "update:searchInput": function($event) {
                  _vm.search = $event
                },
                "update:search-input": function($event) {
                  _vm.search = $event
                },
                focus: _vm.onFocus,
                blur: _vm.onBlur
              },
              nativeOn: {
                keydown: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.doSearch.apply(null, arguments)
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "prepend-item",
                  fn: function() {
                    return [
                      _vm.search
                        ? _c("category-block", {
                            staticClass: "category-block category-block-1",
                            attrs: {
                              target: _vm.searchBarCategory,
                              position: "position1"
                            }
                          })
                        : _vm._e()
                    ]
                  },
                  proxy: true
                },
                {
                  key: "append-item",
                  fn: function() {
                    return [
                      _vm.search
                        ? _c("category-block", {
                            staticClass: "category-block category-block-2",
                            attrs: {
                              target: _vm.searchBarCategory,
                              position: "position2"
                            }
                          })
                        : _vm._e()
                    ]
                  },
                  proxy: true
                },
                {
                  key: "item",
                  fn: function({ item }) {
                    return [
                      _c(
                        "div",
                        { staticClass: "d-flex w-100" },
                        [
                          item.type == "Ricerche"
                            ? _c(
                                "v-list-item-icon",
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: { small: "" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          return _vm.deleteWord(item)
                                        }
                                      }
                                    },
                                    [_vm._v(" $close ")]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-list-item-content",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.doSearch(item)
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-item-title",
                                { class: item.type },
                                [
                                  _c(
                                    "text-highlight",
                                    {
                                      attrs: {
                                        queries: _vm.search ? _vm.search : "*"
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(item.name) + " ")]
                                  )
                                ],
                                1
                              ),
                              item.type == "Categorie"
                                ? _c(
                                    "v-list-item-subtitle",
                                    [
                                      item.count
                                        ? _c(
                                            "v-badge",
                                            {
                                              attrs: {
                                                inline: "",
                                                content: item.count,
                                                color: "secondary"
                                              }
                                            },
                                            [
                                              _vm._v(" Cerca "),
                                              _c("strong", [
                                                _vm._v(_vm._s(_vm.search) + " ")
                                              ]),
                                              _vm._v(
                                                " in " + _vm._s(item.name) + " "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              item.type == "Marche"
                                ? _c("v-list-item-subtitle", [
                                    _vm._v(" Cerca "),
                                    _c("strong", [
                                      _vm._v(" " + _vm._s(_vm.search) + " ")
                                    ]),
                                    _vm._v(
                                      " con marca " + _vm._s(item.name) + " "
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.selected,
                callback: function($$v) {
                  _vm.selected = $$v
                },
                expression: "selected"
              }
            },
            [
              _c(
                "template",
                { slot: "append" },
                [
                  _c(
                    "v-icon",
                    {
                      attrs: { "aria-label": "Cerca", color: "default" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.doSearch.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("$search")]
                  )
                ],
                1
              )
            ],
            2
          ),
          _vm.isCordova
            ? _c("Barcode", {
                attrs: { formats: _vm.barcodeFormats },
                on: { input: _vm.doSearchQRCode }
              })
            : _vm._e(),
          _vm.scannerError
            ? _c(
                "div",
                {
                  staticClass:
                    "text-center warning--text warning lighten-3 px-2 py-3 mt-2 rounded font-weight-semibold"
                },
                [_vm._v(" " + _vm._s(_vm.scannerError) + " ")]
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }