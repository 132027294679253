<template>
  <v-container class="pa-0 category-tree-container">
    <div class="header d-flex flex-column">
      <v-row no-gutters justify="space-between" class="ma-2">
        <img
          class="px-0 logo"
          :height="$vuetify.breakpoint.smAndDown ? '42px' : '63'"
          src="/logo/logo.png"
          alt="Logo"
        />
        <v-btn depressed icon class="white pa-0" @click="$emit('close')">
          <v-icon large>$close</v-icon>
        </v-btn>
      </v-row>
      <h4 class="secondary--text ma-2 d-none d-md-inline">
        {{ $t("navbar.categoryTitle") }}
      </h4>
      <v-tabs
        v-model="selectedTab"
        class="px-2 mb-4 d-inline d-md-none"
        hide-slider
        active-class="activeTab"
      >
        <v-tab key="categories">
          <h6 class="text-none">{{ $t("navbar.products") }}</h6>
        </v-tab>
        <v-tab key="info" v-if="$vuetify.breakpoint.smAndDown">
          <h6 class="text-none">{{ $t("navbar.links") }}</h6>
        </v-tab>
      </v-tabs>
    </div>
    <v-tabs-items v-model="selectedTab">
      <v-tab-item key="categories">
        <v-list class="category-tree px-2 px-sm-0">
          <v-list-group
            v-for="category in categories"
            :key="category.categoryId"
            class="category1"
            color="secondary"
          >
            <template v-slot:activator>
              <v-list-item-title class="font-weight-bold">
                <router-link
                  :to="{
                    name: 'Category',
                    params: { pathMatch: category.slug }
                  }"
                  event
                  @click.native.prevent="nop"
                >
                  {{ category.name }}
                </router-link>
              </v-list-item-title>
            </template>
            <template v-for="category2 in category.categories">
              <v-list-item
                link
                :to="{
                  name: 'Category',
                  params: { pathMatch: category2.slug }
                }"
                class="category2"
                :key="category2.categoryId"
              >
                <v-list-item-title>{{ category2.name }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list-group>
        </v-list>
      </v-tab-item>
      <v-tab-item key="info" v-if="$vuetify.breakpoint.smAndDown">
        <v-list class="info-tab px-2">
          <v-list-item
            v-for="(link, infoLinkId) in infoLinks"
            :key="infoLinkId"
            class="link accent mb-1"
            :href="link.itemUrl"
            @click="clicked"
          >
            <v-list-item-icon>
              <v-icon color="default">{{ `$${link.classes}` }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title :class="link.classes" class="text--bold">
              {{ link.name }}
            </v-list-item-title>
          </v-list-item>
          <template v-for="(item, primaryLinkIdx) in primaryLinks">
            <v-list-item
              v-if="item.templateNavItems && item.templateNavItems.length <= 0"
              :key="primaryLinkIdx"
              class="menuItem"
              :href="item.itemUrl"
              @click="clicked"
            >
              <v-list-item-title class="ml-0" :class="item.classes">
                {{ item.name }}
              </v-list-item-title>
            </v-list-item>
            <v-list-group
              class="menuItem"
              v-else
              :key="primaryLinkIdx"
              color="secondary"
            >
              <template v-slot:activator>
                <v-list-item-title>
                  <a :href="item.itemUrl" event> {{ item.name }}</a>
                </v-list-item-title>
              </template>
              <div
                v-for="(child, childIdx) in item.templateNavItems"
                :key="childIdx"
              >
                <v-list-item link :href="child.itemUrl" @click="clicked">
                  <v-list-item-title class="ml-1">
                    {{ child.name }}
                  </v-list-item-title>
                </v-list-item>
                <v-list
                  class="py-0"
                  dense
                  v-if="child.templateNavItems?.length"
                >
                  <v-list-item
                    v-for="(child2, idx3) in child.templateNavItems"
                    :key="idx3"
                    link
                    :href="child2.itemUrl || '#'"
                    @click="clicked"
                    class="text-caption default--text ml-3"
                    :class="child2.classes"
                  >
                    <span :class="child2.classes">{{ child2.name }}</span>
                  </v-list-item>
                </v-list>
              </div>
            </v-list-group>
          </template>
        </v-list>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import deliveryReactive from "~/mixins/deliveryReactive";
import { mapState, mapActions } from "vuex";
import clickHandler from "~/mixins/clickHandler";
export default {
  name: "CategoryTree",
  mixins: [deliveryReactive, clickHandler],
  props: { primaryLinks: { type: Array } },
  data() {
    return {
      selectedTab: null,
      expandedKeys: [],
      selectedKey: null,
      infoLinks: null
    };
  },
  computed: {
    ...mapState({
      categories: ({ category }) => category.categoryTree
    })
  },
  methods: {
    ...mapActions({
      reload: "category/loadCategoryTree"
    }),
    close() {
      this.$emit("close");
    },
    nop() {}
  },
  created() {
    this.reload(true);
  }
};
</script>

<style scoped lang="scss">
.category-tree-container {
  background-repeat: no-repeat;
  background-position: bottom right;

  .header {
    .v-tabs {
      position: relative;
      top: 1px;
      z-index: 1;
      :first-child {
        margin-right: 2px;
      }
      :last-child {
        margin-left: 2px;
      }
      .v-tab {
        width: 44%;
        color: var(--v-secondary-base) !important;
        background-color: transparent;
        border-radius: 4px;
      }
      .v-tabs-slider-wrapper {
        color: transparent !important;
      }
      .v-tab--active {
        background-color: var(--v-secondary-base);
        h6 {
          color: $white !important;
        }
        &::before {
          opacity: 0;
        }
      }
    }
  }
  .menuItem .v-list-item {
    padding-left: 0px;
  }
}
.category-tree {
  padding: 0;
  a {
    text-decoration: none;
  }
  .v-list-group {
    border-bottom: 1px solid $border-color;
  }
  .category1 {
    a {
      color: $text-color;
      font-size: 14px;
    }
    &.v-list-item--active {
      a {
        color: var(--v-primary-base);
        ::before {
          opacity: 0;
        }
      }
      &.v-list-group__header {
        color: var(--v-primary-base);
        ::before {
          opacity: 0;
        }
      }
    }
    .v-list-item__title {
      padding-left: 0;
      color: $text-color;
    }
  }
  .category2 {
    .v-list-item__title {
      font-size: 14px;
      text-transform: initial;
      color: $text-color !important;
    }
    &.v-list-item--active {
      background-color: transparent;
      color: transparent;
      .v-list-item__title {
        color: $text-color;
        font-weight: bold;
      }
      &:before {
        opacity: 1;
      }
    }
    &.v-list-item--link {
      padding-left: 24px;
    }
  }
}

.info-tab {
  padding: 0;
  .link {
    min-height: 40pt;
    .v-list-item__icon {
      margin: 0 !important;
      align-self: center;
      font-size: 30px;
    }
    .v-list-item__title {
      margin-left: 16px;
    }
  }
  .menuItem:has(div.v-list-group) {
    padding-left: 0px;
  }
  .menuItem {
    min-height: 40pt;
    padding-right: 0;
    border-bottom: 1px solid $border-color;
    .v-list-item__icon {
      height: 50pt;
      margin: 0 !important;
      font-size: 30px;
    }
    .v-list-item__title {
      font-weight: bold;
      a {
        color: $text-color;
        font-size: 14px;
        text-decoration: none;
      }
    }
    .v-list-group {
      width: 100%;
      &__items .v-list-item {
        padding-left: 16px;
      }

      .v-list-group__header {
        padding-right: 0 !important;
        min-height: 50pt;
        .v-list-item__title {
          margin-left: 0;
        }
      }
    }
    .v-list-group--active {
      .v-list-group__header {
        .v-list-item__title {
          a {
            color: var(--v-primary-base) !important;
          }
        }
      }
    }
    .v-list-item--active {
      height: 50pt;
    }
  }
}
</style>
