<template>
  <v-fab-transition>
    <v-btn
      v-show="$route.path === '/'"
      fab
      fixed
      center
      rounded
      color="secondary"
      class="show-card-btn"
      @click="showDetails"
      :loading="showOverlay"
    >
      <img src="/img_layout/carta-gala.svg" class="mr-2" />
      <span>Carta Gala Club</span>
    </v-btn>
  </v-fab-transition>
</template>
<style lang="scss">
.show-card-btn {
  right: 50%;
  bottom: 80px;
  transform: translate(50%, 0);
  height: 45px !important;
  width: 80vw !important;
  max-width: 280px;
  padding: 8px 16px !important;
  .description {
    display: inline;
  }
}
.is-cordova.platform-ios {
  .show-card-btn {
    bottom: 130px;
  }
}
</style>
<script>
import CardDetails from "@/components/profile/CardDetails.vue";
import login from "~/mixins/login";
import { mapGetters } from "vuex";

export default {
  mixins: [login],
  data() {
    return {
      fab: false
    };
  },
  computed: {
    ...mapGetters({
      showOverlay: "app/showOverlay"
    })
  },
  methods: {
    async showDetails() {
      if (this.isAuthenticated) {
        if (!this.$route.hash) {
          this.$router.push("/#");
        }
        let res = await this.$dialog.show(CardDetails, {
          waitForResult: true,
          fullscreen: true
        });
        if (res) {
          console.log(res);
        } else if (this.$route.hash) {
          this.$router.go(-1);
        }
      } else {
        this.doLogin();
      }
    }
  }
};
</script>
