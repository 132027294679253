var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    { attrs: { column: "" } },
    [
      _c(
        "v-row",
        {
          staticClass: "pa-3 mt-5 text-center",
          attrs: { "no-gutters": "", justify: "center", align: "center" }
        },
        [_vm._v(_vm._s(_vm.$t("message.checkForMissingDisclaimer")))]
      ),
      _c(
        "v-row",
        {
          staticClass: "py-3",
          attrs: { "no-gutters": "", justify: "center", align: "center" }
        },
        [
          _c(
            "v-btn",
            {
              attrs: {
                large: "",
                color: "primary",
                to: {
                  name: "ProfileUpdate",
                  params: {
                    navToPreferences: true
                  }
                }
              },
              on: { click: _vm.onClick }
            },
            [_vm._v(_vm._s(_vm.$t("profile.goToProfileBtn")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }