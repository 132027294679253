var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isSsoUser
    ? _c(
        "v-menu",
        {
          staticClass: "sso-user-card",
          attrs: { bottom: "", "offset-y": "", left: "" },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function({ on, attrs }) {
                  return [
                    _vm.isSsoUser
                      ? _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              {
                                staticClass: "sso-btn",
                                attrs: {
                                  fab: "",
                                  fixed: "",
                                  top: "",
                                  right: "",
                                  color: "primary"
                                }
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [
                            _c("v-icon", { attrs: { large: "" } }, [
                              _vm._v("$eye")
                            ])
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              }
            ],
            null,
            false,
            1348204647
          )
        },
        [
          _c(
            "v-list",
            { staticClass: "sso-user-list" },
            [
              _c("v-list-item", [_vm._v(" Sei loggato come ")]),
              _c("v-list-item", [
                _c("strong", [_vm._v(_vm._s(_vm.isSsoUser.username))])
              ]),
              _c("v-divider", { staticClass: "divider" }),
              _c(
                "v-list-item",
                {
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.refresh()
                    }
                  }
                },
                [
                  _c("v-list-item-title", {
                    domProps: { textContent: _vm._s("Refresh") }
                  })
                ],
                1
              ),
              _c(
                "v-list-item",
                {
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.logout()
                    }
                  }
                },
                [
                  _c("v-list-item-title", {
                    domProps: { textContent: _vm._s("Esci") }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }