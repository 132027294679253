var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { "fill-height": "", fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { justify: "center" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "d-flex align-center mb-6 justify-center",
                  attrs: {
                    color: "grey lighten-2",
                    flat: "",
                    height: "100",
                    tile: ""
                  }
                },
                [
                  _c(
                    "v-card",
                    { staticClass: "pa-2", attrs: { flat: "", tile: "" } },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            {
                              staticClass:
                                "text-center overline mb-1 font-weight-bold"
                            },
                            [_vm._v(" " + _vm._s(_vm.getVersionTile) + " ")]
                          ),
                          _c(
                            "v-list-item-subtitle",
                            { staticClass: "text-center" },
                            [
                              _c("strong", { staticClass: "primary--text" }, [
                                _vm._v(_vm._s(_vm.getVersion))
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "reload-button",
                          attrs: {
                            large: "",
                            outlined: "",
                            width: "100%",
                            depressed: ""
                          },
                          on: { click: _vm.reloadNewVersion }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("common.upgradeVersion")) + " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }